import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordModal from './ForgotPassword'; // Import ForgotPasswordModal

const LoginModal = ({ show, handleClose, switchToSignup, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false); // State for Forgot Password modal
  const navigate = useNavigate();

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);

  const handleLogin = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setLoading(true); // Show spinner
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Account/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        localStorage.setItem('userData', JSON.stringify(data));
        window.location.reload(true);
        onLoginSuccess(); // Update login status in Navigation
        handleClose();
        
        // Conditional redirection based on screen width
        if (window.innerWidth <= 768) {
          navigate('/dashboard'); // Redirect to /dashboard on mobile
        } else {
          navigate('/dashboard/complaints'); // Redirect to /dashboard/complaints on desktop
        }
      } else if (response.status === 400) {
        const errorText = await response.text(); // Get error text if status is 400
        setErrorMessage(errorText || 'Login failed. Please try again.');
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('An error occurred during login.');
    } finally {
      setLoading(false); // Hide spinner
    }
  };
  
  const handleForgotPassword = () => {
    setShowForgotPassword(true);
    handleClose(); // Close the login modal
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Modal.Body style={{ position: 'relative' }}>
          <Button
            variant="close"
            onClick={handleClose}
            style={{ position: 'absolute', top: '10px', right: '10px' }}
          >
            {/* &times; */}
          </Button>
          <div className="text-center mb-4">
            <img
              src="../assets/images/phishroko_cropped.png"
              alt="Logo"
              style={{ width: '200px', height: 'auto' }}
            />
          </div>
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Control
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ paddingRight: '40px' }}
              />
              <Button
                variant="link"
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </Button>
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            <div className="text-center mb-3">
              <Button 
                type="submit" 
                variant="primary"
                disabled={loading} // Disable button while loading
                style={{ position: 'relative' }}
              >
                {loading ? '  Logging in' : 'Login'}
              </Button>
            </div>
            <div className="text-center">
              <Button
                variant="link"
                onClick={handleForgotPassword}
                style={{ color: '#007bff' }} // Adjust color if needed
              >
                Forgot your password?
              </Button>
            </div>
            <div className="text-center">
              <Button variant="link" onClick={switchToSignup}>
                Don't have an account? Sign up
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Forgot Password Modal */}
      <ForgotPasswordModal
        show={showForgotPassword}
        handleClose={() => setShowForgotPassword(false)}
      />
    </>
  );
};

export default LoginModal;
