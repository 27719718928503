import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navigation.css";
import LoggedInNavbar from "./LoggedInNavbar";
import NotLoggedInNavbar from "./NotLoggedInNavbar";

const Navigation = () => {
  const [navbarStyle, setNavbarStyle] = useState("navbar-transparent");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInitials, setUserInitials] = useState("");
  const [fullName, setFullName] = useState("");
  const [showModal, setShowModal] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [userDetails, setUserDetails] = useState(null); // Define userDetails state
  const navigate = useNavigate();
  const mobileMenuRef = useRef(null); // Ref for mobile menu

  const fetchUserDetails = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem("userData"))?.userId;
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/User/get-user-details?id=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUserDetails(data); // This will now work as setUserDetails is defined
      } else {
        console.error("Failed to fetch user details");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleProfileClick = () => {
    fetchUserDetails();
    // This method needs to be updated to manage profile modal visibility
  };

  const handleOpenLogin = () => setShowModal("login");
  const handleOpenSignup = () => setShowModal("signup");
  const handleClose = () => setShowModal(null);

  const handleOpenChangePasswordModal = () => {
    setShowModal("changePassword");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("userData"));

    if (token && user) {
      setIsLoggedIn(true);
      setFullName(user.fullName);
      const initials = user.fullName.charAt(0).toUpperCase();
      setUserInitials(initials);
      setNavbarStyle("navbar-logged-in");
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLoginSuccess = () => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("userData"));

    if (token && user) {
      setIsLoggedIn(true);
      setFullName(user.fullName);
      const initials = user.fullName.charAt(0).toUpperCase();
      setUserInitials(initials);
      setNavbarStyle("navbar-logged-in");
      handleClose();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    setIsLoggedIn(false);
    setUserInitials("");
    setFullName("");
    setNavbarStyle("navbar-transparent");
    navigate("/");
  };

  // Collapse mobile menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setShowMobileMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileMenuRef]);

  return (
    <nav className={`navbar navbar-expand-lg fixed-top ${navbarStyle}`}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img
            src="../assets/images/phishroko_cropped.png"
            alt="Logo"
            className="navbar-logo"
          />
        </Link>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            {/* Add navigation links here */}
          </ul>

          {/* For desktop view */}
          {isLoggedIn ? (
            <LoggedInNavbar
              userInitials={userInitials}
              fullName={fullName}
              handleProfileClick={handleProfileClick}
              handleLogout={handleLogout}
              handleOpenChangePasswordModal={handleOpenChangePasswordModal}
            />
          ) : (
            <NotLoggedInNavbar
              handleOpenLogin={handleOpenLogin}
              handleOpenSignup={handleOpenSignup}
              showModal={showModal}
              handleClose={handleClose}
              handleLoginSuccess={handleLoginSuccess}
            />
          )}
        </div>

        {/* For mobile view */}
        {isLoggedIn ? (
          <div className="d-lg-none" ref={mobileMenuRef}>
            {/* Display user icon button when logged in */}
            <div className="user-icon" onClick={() => setShowMobileMenu(!showMobileMenu)}>
              {userInitials || "?"}
            </div>

            {/* User options for mobile view */}
            {showMobileMenu && (
              <div className="mobile-user-menu">
                <button
  style={{
    backgroundColor: '#ab1119',
    color: 'white',
    border: 'none',
    padding: '10px',
    borderRadius: '5px',
    height: '40px', // Increased height for better visibility
    width: '100%', // Ensures the button takes full width of the parent
    display: 'flex', // Use flexbox to center text
    alignItems: 'center', // Center vertically
    justifyContent: 'center', // Center horizontally
  }}
  onClick={handleLogout}
>
  Logout
</button>

              </div>
            )}
          </div>
        ) : (
          <button
            className="btn btn-primary d-lg-none"
            onClick={() => setShowModal("login")}
          >
            Login/Register
          </button>
        )}
      </div>
    </nav>
  );
};

export default Navigation;
