import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import { FaExclamationCircle, FaDollarSign, FaUsers, FaComments, FaLink } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import mobileBackgroundImage from '../../assets/images/subtle-prism.png';

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State to track sidebar visibility on mobile
  const username = JSON.parse(localStorage.getItem('userData'))?.username || 'Guest';

  const dashboardContainerStyle = {
    display: 'flex',
    minHeight: '100vh',
    paddingTop: '20px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
  };

  const sidebarStyle = {
    position: 'fixed',
    top: '60px',
    width: '20%', // Default width for larger screens
    height: 'calc(100vh - 80px)',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    backgroundColor: '#f8f9fa',
    overflowY: 'auto',
  };

  const mobileSidebarStyle = {
    ...sidebarStyle,
    width: isSidebarOpen ? '100%' : '0', // Show or hide sidebar based on state
    height: isSidebarOpen ? '100vh' : '0',
    top: '60px', 
    backgroundImage: `url(${mobileBackgroundImage})`, // Use the imported image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    transition: 'width 0.3s ease',
  };

  const contentStyle = {
    marginLeft: '20%',
    padding: '0rem',
    width: '80%',
  };

  const mobileContentStyle = {
    display: isSidebarOpen ? 'none' : 'block', // Hide content area when sidebar is open on mobile
    padding: '1rem',
  };

  const cardBaseStyle = {
    height: '75px',
    borderRadius: '10px',
    color: '#fff',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'none',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    transform: 'translateY(0)',
  };

  const hoverEffect = {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.25)',
  };

  const normalEffect = {
    transform: 'translateY(0)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
  };

  const solidColors = [
    { backgroundColor: '#FF9900' },
    { backgroundColor: '#34a853' },
    { backgroundColor: '#6599FF' },
    { backgroundColor: '#ff7043' },
    { backgroundColor: '#097054' },
  ];

  const handleCardClick = (to) => {
    console.log(`Navigating to ${to}`);
    if (window.innerWidth <= 768) {
      setIsSidebarOpen(false); // Close sidebar on mobile after clicking
    }
  };

  const renderCard = (to, style, icon, text) => {
    // Determine the correct path based on screen width
    const path = window.innerWidth <= 768 ? to.replace('/dashboard', '') : to;

    return (
      <Link to={path} style={{ textDecoration: 'none' }} onClick={() => handleCardClick(path)}>
        <Card
          style={{
            ...cardBaseStyle,
            ...style,
          }}
          onMouseEnter={(e) => Object.assign(e.currentTarget.style, hoverEffect)}
          onMouseLeave={(e) => Object.assign(e.currentTarget.style, normalEffect)}
        >
          <Row className="h-100 align-items-center justify-content-center">
            <Col xs={2} className="text-center">
              <span style={{ fontSize: '1.5rem' }}>
                {icon}
              </span>
            </Col>
            <Col xs={10} className="text-center">
              <span style={{ fontSize: '1.25rem' }}>
                {text}
              </span>
            </Col>
          </Row>
        </Card>
      </Link>
    );
  };

  return (
    <div style={dashboardContainerStyle}>
      <div style={window.innerWidth <= 768 ? mobileSidebarStyle : sidebarStyle}>
        {renderCard('/dashboard/complaints', solidColors[0], <FaExclamationCircle />, 'Your Complaints')}
        {renderCard('/dashboard/subscription', solidColors[2], <FaDollarSign />, 'Subscription')}
        {renderCard('/dashboard/community', solidColors[1], <FaUsers />, 'Community')}
        {renderCard('/dashboard/chat', solidColors[3], <FaComments />, 'Chat with Us')}
        {renderCard('/dashboard/reportUrl', solidColors[4], <FaLink />, 'URL Service')}
      </div>
      <div style={window.innerWidth <= 768 ? mobileContentStyle : contentStyle}>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
