import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Card, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';
import CreateTicketModal from './CreateTicketModal';  
import ViewTicketModal from './ViewTicketModal';      
import ImageModal from './ImageModal';                
import AttachmentsModal from './AttachmentsModal';    
import backgroundImage from '../../../assets/images/subtle-prism1.png';

const ComplaintsPage = () => {
  const [showCreateTicketModal, setShowCreateTicketModal] = useState(false);
  const [showTicketDetailsModal, setShowTicketDetailsModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false); 
  const [imageForModal, setImageForModal] = useState("");
  const [attachmentsForModal, setAttachmentsForModal] = useState([]);  
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [ticketDetails, setTicketDetails] = useState({
    title: '',
    description: '',
    attachment: '',
    createFor: JSON.parse(localStorage.getItem('userData'))?.userId || 0,
  });
  
  useEffect(() => {
    fetchTickets();
    const intervalId = setInterval(fetchTickets, 5000); // Poll every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const handleAttachmentClick = (attachmentUrl) => {
    const fileExtension = attachmentUrl.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];

    if (imageExtensions.includes(fileExtension)) {
      setImageForModal(attachmentUrl);
      setShowImageModal(true);
    } else {
      window.open(attachmentUrl, "_blank");
    }
  };

  const handleViewAttachmentsClick = (attachments) => {
    setAttachmentsForModal(attachments.split(";"));
    setShowAttachmentsModal(true);
  };

  const fetchTickets = async () => {
    try {
      const token = localStorage.getItem('token');
      const userId = JSON.parse(localStorage.getItem('userData'))?.userId;
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Ticket/user-tickets?userId=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTickets(response.data);
    } catch (error) {
      console.error('Failed to fetch tickets:', error);
    }
  };

  const handleChange = (e) => {
    setTicketDetails({ ...ticketDetails, [e.target.name]: e.target.value });
  };

  const handleCardClick = (ticket) => {
    setSelectedTicket(ticket);
    setShowTicketDetailsModal(true);
  };

  const filteredTickets = tickets.filter((ticket) =>
    ticket.id.toString().includes(searchTerm)
  );

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return { text: "Open", color: "blue" };
      case 1:
        return { text: "Assigned", color: "orange" };
      case 2:
        return { text: "Resolved", color: "green" };
      case 3:
        return { text: "Closed", color: "grey" };
      default:
        return { text: "Unknown", color: "black" };
    }
  };

  return (
    <div style={{
      padding: '3rem 1rem',
      position: 'relative',
      height: '100%',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}>
      <div style={{
        position: 'fixed',
        top: '60px',
        left: '20%',
        right: '0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'calc(100% - 20%)',
        padding: '0 1rem',
        zIndex: 10,
        backgroundColor: '#fafafa',
        height: '60px',
        borderBottom: '1px solid #ddd',
      }}>
        <Button
          variant="primary"
          onClick={() => setShowCreateTicketModal(true)}
          style={{ marginRight: '10px' }}
        >
          Create Ticket
        </Button>
        <InputGroup className="d-flex" style={{ width: '100%', maxWidth: '500px' }}>
          <FormControl
            placeholder="Search by Ticket ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
      </div>

      <div className="mt-5" style={{ marginTop: '6rem' }}>
        <h2 style={{ textAlign: 'center', marginTop: '2rem', fontSize: '2rem' }}>Your Tickets</h2>
        <Row className="justify-content-center">
          {filteredTickets.length > 0 ? (
            filteredTickets.map((ticket) => (
              <Col key={ticket.id} xs={12} sm={6} md={4} className="d-flex justify-content-center mb-4">
                <Card
                  className="p-3 border-0 shadow-lg"
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                    borderRadius: '18px',
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleCardClick(ticket)}
                >
                  <Card.Body>
                    <Card.Title className="text-primary" style={{ fontSize: '1.5rem' }}>
                      ID: {ticket.ticketId}
                    </Card.Title>
                    <Card.Subtitle className="mb-3" style={{ fontSize: '1.25rem', color: '#000' }}>
                      <strong>Title:</strong> {ticket.title}
                    </Card.Subtitle>
                    <Card.Text>
                      <strong>Description:</strong> {ticket.desc}
                    </Card.Text>
                    <p>
                      <strong>Status:</strong>{" "}
                      <span style={{ color: getStatusText(ticket.status).color, fontWeight: 'bold' }}>
                        {getStatusText(ticket.status).text}
                      </span>
                    </p>
                    {ticket.attachment && (
                      <p>
                        <strong>Attachments:</strong>{" "}
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation(); 
                            handleViewAttachmentsClick(ticket.attachment);
                          }}
                        >
                          View Attachments
                        </span>
                      </p>
                    )}
                    <p>
                      <strong>Created At:</strong> {new Date(ticket.createdAt).toLocaleString()}
                    </p>
                    {ticket.status !== 0 && (
                      <p>
                        <strong>Last Updated At:</strong> {new Date(ticket.updatedAt).toLocaleString()}
                      </p>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <p style={{ textAlign: 'center', fontSize: '1.5rem', color: '#666', paddingTop: '3rem' }}>
              No tickets available
            </p>
          )}
        </Row>
      </div>

      <CreateTicketModal
        show={showCreateTicketModal}
        onHide={() => setShowCreateTicketModal(false)}
        ticketDetails={ticketDetails}
        handleChange={handleChange}
        file={file}
        setFile={setFile}
        uploadProgress={uploadProgress}
        setUploadProgress={setUploadProgress}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        fetchTickets={fetchTickets}
      />

      <ViewTicketModal
        show={showTicketDetailsModal}
        onHide={() => setShowTicketDetailsModal(false)}
        selectedTicket={selectedTicket}
      />

      <ImageModal
        show={showImageModal}
        onHide={() => setShowImageModal(false)}
        imageSrc={imageForModal}
      />

      <AttachmentsModal
        show={showAttachmentsModal}
        onHide={() => setShowAttachmentsModal(false)}
        attachments={attachmentsForModal}
        onAttachmentClick={handleAttachmentClick}
      />
    </div>
  );
};

export default ComplaintsPage;
