import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Container, Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import backgroundImage from '../../assets/images/subtle-prism1.png';

// Currency symbols based on the user's country
const currencySymbols = {
  'USA': { currency: 'USD', symbol: '$' },
  'Canada': { currency: 'CAD', symbol: 'CA$' },
  'IND': { currency: 'INR', symbol: '₹' },
  'AU': { currency: 'AUD', symbol: 'AU$' },
  'NZ': { currency: 'NZD', symbol: 'NZ$' },
  'UK': { currency: 'GBP', symbol: '£' }
};

const getCurrencySymbol = (currencyCode) => {
  const currency = Object.values(currencySymbols).find(c => c.currency === currencyCode);
  return currency ? currency.symbol : '$'; // Default to '$' if currency not found
};
 
const commonListItemStyle = {
  margin: '10px 0',
  fontSize: '16px'
};

const starIconStyle = {
  color: '#ffd700',
  marginRight: '8px'
};

const SubscriptionPage = () => {
  const [plans, setPlans] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalFooter, setModalFooter] = useState(<Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>);
  const [currentPlan, setCurrentPlan] = useState('');

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const token = localStorage.getItem('token');
        const userCountry = JSON.parse(localStorage.getItem('userData'))?.country || 'IND';
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Subscription/get-plans`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        
        // Filter and sort plans
        const filteredPlans = data
          .filter(plan => plan.country === userCountry)
          .sort((a, b) => parseFloat(b.price) - parseFloat(a.price)); // Sort in descending order
  
        setPlans(filteredPlans);
    
        // Set currency symbol based on user country
        setCurrencySymbol(currencySymbols[userCountry]?.symbol || '$');
  
        // Get current plan from localStorage
        const userData = JSON.parse(localStorage.getItem('userData'));
        const subscriptionStatus = userData?.subscription;
        
        if (subscriptionStatus === 0) {
          setCurrentPlan('No Active Plan');
        } else if (subscriptionStatus === 1) {
          const activePlan = JSON.parse(localStorage.getItem('currentPlan')) || {};
          // setCurrentPlan(`Active Plan: ${activePlan.name || 'Unknown Plan'}`);
          setCurrentPlan(`Active`);
        } else {
          setCurrentPlan('Subscription Status Unknown');
        }
      } catch (error) {
        console.error('Error fetching subscription plans:', error);
      }
    };
  
    fetchPlans();
  }, []);
  

  const fetchUserDetails = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem("userData"))?.userId;
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/User/get-user-details?id=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const existingUserData = JSON.parse(localStorage.getItem('userData')) || {};
        const updatedUserData = {
          ...existingUserData,
          ...data,
        };
        localStorage.setItem('userData', JSON.stringify(updatedUserData));
        return updatedUserData;
      } else {
        console.error("Failed to fetch user details");
        throw new Error('Failed to fetch user details');
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      throw error;
    }
  };

  const handleCardClick = (plan) => {
    const options = {
      // key: 'rzp_test_aY6FLjVPIqSHzL', // Replace with your Razorpay key
      key:'rzp_live_zSpFw7PZTXT7s3',
      amount: parseFloat(plan.price) * 100, // Amount in paise (currency unit)
      currency: plan.currency,
      name: plan.name,
      description: plan.planId,
      handler: async function (response) {
        setShowModal(true);
        setModalTitle('Verifying Payment');
        setModalBody(
          <div>
            Verifying payment...
            <br />
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        );

        const verificationUrl = `${process.env.REACT_APP_API_BASE_URL}/api/Subscription/verify-payment?paymentId=${response.razorpay_payment_id}`;

        try {
          const verificationResponse = await fetch(verificationUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`, // Include Bearer token
            },
          });
          const verificationData = await verificationResponse.json();

          if (verificationData === true) { // Check if response is true
            setModalTitle('Payment Verified');
            setModalBody('Payment successfully verified!');

            const updatedUserData = await fetchUserDetails();
            setModalFooter(
              <Button variant="primary" onClick={() => {
                setShowModal(false);
                window.location.href = '/dashboard/complaints'; // Redirect to complaints page
              }}>
                Go to Dashboard
              </Button>
            );
          } else {
            throw new Error('Payment verification failed');
          }
        } catch (error) {
          console.error('Error:', error);

          setModalTitle('Verification Failed');
          setModalBody('Payment verification or user details update failed. Please try again.');
          setModalFooter(
            <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          );
        }
      },
      prefill: {
        name: JSON.parse(localStorage.getItem('userData'))?.fullName,
        email: JSON.parse(localStorage.getItem('userData'))?.email,
        contact: JSON.parse(localStorage.getItem('userData'))?.contact,
      },
      theme: {
        color: '#5491f3',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div
      className="py-3 text-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        padding: '1rem 0',
        color: '#fff',
        top: '60px',
        position: 'fixed', // Fixes the entire page
        width: '80%',
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <Container fluid>
        <Row className="mb-3">
          <Col className="d-flex justify-content-end">
            <div style={{ fontSize: '1.2rem', color: '#000' }}>
              Subscription Status: {currentPlan}
            </div>
          </Col>
        </Row>

        <div className="mb-5">
          <h2 className="display-4 text-black mb-4 font-weight-bold">Choose Your Plan</h2>
        </div>

        <Row className="justify-content-center">
          {plans.length > 0 ? (
            plans.map((plan) => (
              <Col key={plan.id} md={6} className="d-flex justify-content-center mb-4">
  <Card
    className="p-3 border-0 shadow-lg"
    style={{
      width: window.innerWidth < 768 ? '100%' : '60%', // Use 100% width for mobile and 60% for desktop
      maxWidth: '500px', // Maximum width for larger screens
      height: '450px', // Decreased height
      borderRadius: '18px',
      cursor: 'pointer',
      backgroundColor: '#fff',
      transition: 'transform 0.3s ease-in-out',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)' // Added shadow
    }}
    onClick={() => handleCardClick(plan)}
    onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
    onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
  >
    <Card.Body>
      <Card.Title className="text-primary" style={{ fontSize: '1.5rem' }}>
        {plan.name}
      </Card.Title>
      <Card.Subtitle className="mb-3" style={{ fontSize: '2rem', color: '#000' }}>
        {getCurrencySymbol(plan.currency)} {plan.price}
        <span className="d-block mt-2"></span>
      </Card.Subtitle>

      <ul className="list-unstyled">
        {plan.description.split('~').filter(Boolean).map((desc, index, arr) => (
          <li
            key={index}
            className={`mb-2 ${index >= arr.length - 2 ? 'text-center' : 'text-start'}`}
            style={{
              fontSize: index === arr.length - 2 ? '2rem' : 'inherit',
              textAlign: index >= arr.length - 2 ? 'center' : 'left'
            }}
          >
            {index === arr.length - 2 ? (
              <>
                {getCurrencySymbol(plan.currency)} {desc}
              </>
            ) : (
              <>
                {index < arr.length - 2 && (
                  <span style={starIconStyle}>★</span>
                )}
                {desc}
              </>
            )}
          </li>
        ))}
      </ul>

      <Button variant="primary" className="w-100">Select Plan</Button>
    </Card.Body>
  </Card>
</Col>


            ))
          ) : (
            <p>No plans available</p>
          )}
        </Row>
      </Container>

      {/* Modal for payment verification */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalBody}
        </Modal.Body>
        <Modal.Footer>
          {modalFooter}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubscriptionPage;
